import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";
import { navigate } from "gatsby"
import Formular from "./Formular";
import Hlavni from "./Hlavni";
import { useCookies } from "react-cookie";
import axios from "axios";
import SEO from "../../../components/SEO";





const Default = () => {
  navigate("/404");
  return (<></>);
}


const Redirect = ({url}) => {
  navigate(url);
  return (<></>);
}



const PrehledInner = ({ getLoginId }) => {

  const [data, setData] = useState();
  const [jwtState, setJwtState] = useState();

  let appData = [];






  










  // nastavení správné backend url

  let backendUrl = "";

  if (process.env.NODE_ENV === 'development') {
    backendUrl = 'http://localhost:1337'
  }

  if (process.env.NODE_ENV === 'production') {
    backendUrl = 'https://goodbye-backend.herokuapp.com'
  }


  const updateUserData = (userData, setLoading) => {

    if (jwtState) {
      axios.put(`${backendUrl}/offers/${getLoginId()}`, userData, {
        headers: {
          "Authorization": `Bearer ${jwtState}`,
          "Content-Type": "application/json"
        },
      }).then(res => {
        //hotovo
        reloadData(jwtState, (e) => setLoading(e));
        //nacitam data
        
      });
    }

    else {
      console.log("chyba při přihlašování");
    }
  }


  const reloadData = (jwt, setLoading) => {

    axios.get(`${backendUrl}/reloaddata/${getLoginId()}`, {
      headers: {
        "Authorization": `Bearer ${jwt}`,
        "Content-Type": "application/json"
      },
    }).then(res => {
      setData(res.data);
      
      if (setLoading) {
        setLoading(false);
      }
    });

  }



  useEffect(() => {

    let jwt;

    axios.post(`${backendUrl}/auth/local`, {
      identifier: 'app@goodbye.cz',
      password: 'Good.Bye123',
    }).then(res => {

      jwt = res.data.jwt;
      setJwtState(res.data.jwt)

      reloadData(jwt);
    });
	}, []);



  return (<>
    <SEO
      title="Vaše nabídka na pohřeb | Goodbye.cz"
      customTitle
      noindex
    />

    <Router basepath="/nabidka/prehled">

      <Hlavni
        data={data}
        path="/"
        updateUserData={updateUserData}
        jwt={jwtState}
      />

      <Formular
        path="/formular"
        data={data}
        updateUserData={updateUserData}
        jwt={jwtState}
        reloadData={reloadData}
      />

      <Default default />

    </Router>
  </>)
}

export default PrehledInner;